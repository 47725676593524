﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions} from "ag-grid-community";
import { FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ChildMessageRendererComponent} from '../../common/child-message-renderer.component';
import { ParamsRendererComponent} from '../../common/params-renderer.component';
import { CellEditChangeRendererComponent} from '../../common/cell-edit-change-renderer.component';
import { CellEditDatepickerRendererComponent} from '../../common/cell-edit-datepicker-renderer.component';
import { PopUpRendererComponent} from '../../common/pop-up-renderer.component';
import { DropDownListRendererComponent} from '../../common/drop-down-list-renderer.component';
import { WorkClsRendererComponent } from '../../common/work-cls-renderer.component';
import { SlideToggleRendererComponent } from '../../common/slide-toggle-renderer.component';
import { CheckBoxRendererComponent } from '../../common/check-box-renderer.component';
import { PassWordRendererComponent } from '../../common/pass-word-renderer.component';
import { CustomLoadingOverlayComponent} from '../../common/custom-loading-overlay.component';
import { CustomNoRowsOverlayComponent} from '../../common/custom-no-rows-overlay.component';
import { MatDialog } from '@angular/material';

import { ContractService} from '../../services/Contract.service';
import { DatePipe, formatDate } from '@angular/common'
import { isNullOrUndefined } from 'util';

import { MatInput, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../common/date.adapter';
import { ContractData } from '../../models/model.component';
import { PopUpDialogContractComponent } from '../../common/popup/pop-up-dialog-contract.component';

import {AppComponent} from '../../app.component';

export interface stat {
    value: string;
    viewValue: string;
  }

@Component({
    selector: 'app-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.scss'],
    providers: [ {provide: DateAdapter, useClass: AppDateAdapter},
                 {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
               ]
})


/** contract component*/
export class ContractComponent {
    @ViewChild('agGrid') agGrid: AgGridNg2;
    @ViewChild('selectedFromPicker', { read: MatInput }) selectedFromPicker: MatInput;
    @ViewChild('selectedToPicker', { read: MatInput }) selectedToPicker: MatInput;

    //검색 조건 영역 변수
    public rowHeight : any;

    status: stat[] = [
      {value: 'Name', viewValue: '이름'},
      {value: 'EmployeeNumber', viewValue: '사번'},
      {value: 'ContactNumber', viewValue: '연락처'}
    ];

    crtfdPolicy: stat[] = [
      {value: 'LIMIT', viewValue: '제한'},
      {value: 'UNLIMITED', viewValue: '무제한'}
    ];

    cls: stat[] = [
      {value: '01', viewValue: 'Studio'},
      {value: '02', viewValue: 'Robot'},
      {value: '03', viewValue: 'Manager'},
    ];

    // [app.component.ts] 로그인 사용자의 SITE_ID 데이터
    public loginUserSiteId: String;

      // 저장 및 삭제 영역
    static contractComp;  // 전역 변수로 선언하여 외부 Component에서도 동일한 Object를 사용하도록 함.
    static matDial: MatDialog = null;
    public gridOptions: GridOptions;
    private getRowHeight;

    private gridApi;
    private gridColumnApi;

    title = 'app';

    search: any = {};

    columnDefs = [
      { headerName: '', field: '', sortable: true, filter: false, editable:false, checkboxSelection: true, width: 48, headerCheckboxSelection: false, resizable: false },
      { headerName: '사용', field: 'sysFlag', sortable: true, editable: false, width: 57, resizable: true,
        cellRenderer: function(params) {
          switch (params.node.data.sysFlag) {
            case "A" :
              return "<i class='material-icons' style='color:#447ed4; font-size: 20px; font-weight: bold; cursor: pointer'>check</i>";
            case "D" :
              return "<i class='material-icons' style='color:#d66451; font-size: 20px; font-weight: bold; cursor: pointer'>close</i>";
            default :
              return "<i class='material-icons' style='color:#a5a5a5; font-size: 20px; font-weight: bold; cursor: pointer'>remove</i>";
          }
        }
      },
      { headerName: '제품구분', field: 'productCls', sortable: true, filter: true, editable: false, width: 120, resizable: true,
        cellRenderer: "DropDownListRendererComponent",
        cellRendererParams : {values: this.cls, popUpTarget: 'productCls'}
      },
      { headerName: '계약번호', field: 'contractId', sortable: true, filter: true, editable: false, width: 110, resizable: true },
      { headerName: '제품일련번호', field: 'productSeq', sortable: true, filter: true, editable: false, width: 130, resizable: true },
      { headerName: '제품키', field: 'productKey', sortable: true, filter: true, editable: false, width: 200, resizable: true },
      { headerName: '계약주체', field: 'crtfdOnwer', sortable: true, filter: true, editable: true, width: 120, resizable: true },
      { headerName: '계약주체구분', field: 'crtfdOnwerDiv', sortable: true, filter: true, editable: false, width: 140, resizable: true,
        cellRenderer: "DropDownListRendererComponent",
        cellRendererParams : {values: this.status, popUpTarget: 'contract'}
      },

      { headerName: '계약주체 담당자', field: 'crtfdOnwerInfo', sortable: true, filter: true, editable: true, width: 140, resizable: true },
      { headerName: '인증시작일', field: 'crtfdStDt', sortable: true, filter: true, editable: true, width: 200, resizable: true,
        cellRenderer: "CellEditDatepickerRendererComponent",
        cellRendererParams : {
          popUpTarget: 'contract1',
          fieldNm: 'crtfdStDt'
        }
      },
      { headerName: '인증만료일', field: 'crtfdExpDt', sortable: true, filter: true, editable: true, width: 200, resizable: true,
        cellRenderer: "CellEditDatepickerRendererComponent",
        cellRendererParams : {
          popUpTarget: 'contract',
          fieldNm: 'crtfdExpDt'
        }
      },
      { headerName: '허가버전', field: 'crtfdVersion', sortable: true, filter: true, editable: true, width: 100, resizable: true },
      { headerName: '인증댓수', field: 'crtfdAllowCnt', sortable: true, filter: true, editable: true, width: 100, resizable: true },
      { headerName: '인증댓수정책', field: 'crtfdPolicy', sortable: true, filter: true, editable: false, width: 140, resizable: true,
      cellRenderer: "DropDownListRendererComponent",
      cellRendererParams : {values: this.crtfdPolicy, popUpTarget: 'crtfdPolicy'}
    },
    { headerName: '인증체크항목', field: 'crtfdAuthInfo', sortable: true, filter: true, editable: true, width: 210, resizable: true },
    { headerName: '인증제품', field: 'crtfdAuthFeature', sortable: true, filter: true, editable: false, width: 180, resizable: true },
    { headerName: '계약설명', field: 'contractDesc', sortable: true, filter: true, editable: true, width: 180, resizable: true },
    { headerName: '최소버전', field: 'crtfdMinVersion', sortable: true, filter: true, editable: true, width: 100, resizable: true },
    { headerName: '인증기간', field: 'crtfdPeriod', sortable: true, filter: true, editable: true, width: 100, resizable: true },
    { headerName: '', field: 'cls', sortable: true, filter: true, editable:false, width: 68, resizable: false,
      cellRenderer: "WorkClsRendererComponent",
      cellEditor : 'agSelectCellEditor',
      cellRendererParams : {
              fieldNm: 'cls',
              values: ['조회','신규','수정','삭제'],
              cellRenderer: "WorkClsRendererComponent"
              }
      },
      { headerName: '활성화여부', field: 'sysFlag', hide: true }
    ];

    frameworkComponents = {
      CellEditChangeRendererComponent:CellEditChangeRendererComponent,
      PopUpRendererComponent : PopUpRendererComponent,
      DropDownListRendererComponent:DropDownListRendererComponent,
      ChildMessageRendererComponent: ChildMessageRendererComponent,
      WorkClsRendererComponent : WorkClsRendererComponent,
      SlideToggleRendererComponent: SlideToggleRendererComponent,
      CheckBoxRendererComponent : CheckBoxRendererComponent,
      PassWordRendererComponent : PassWordRendererComponent,
      CustomLoadingOverlayComponent : CustomLoadingOverlayComponent,
      CustomNoRowsOverlayComponent : CustomNoRowsOverlayComponent,
      CellEditDatepickerRendererComponent : CellEditDatepickerRendererComponent
    };

    // loaing overlay
    loadingOverlayComponent = "CustomLoadingOverlayComponent";
    noRowsOverlayComponent = "CustomNoRowsOverlayComponent";

    defaultColDef = {
      editable: true,
      resizable: true,
      cellClass: "align-center"
    };

    rowSelection = "multiple";
    rowData: any;

    // overlayNoRowsTemplate =
    // "<span style=\"padding: 100px; border: 4px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>";

    constructor(public http: HttpClient, private _contractService: ContractService, public datepipe: DatePipe, public dialog: MatDialog) {
        ContractComponent.contractComp = this;  // governorComponent 의 AddRow, delete, save 제어를 위해
        ContractComponent.matDial = dialog;

      this.gridOptions = <GridOptions>{
        context: {componentParent: this},
        pagination: true,
        paginationAutoPageSize: true,
        enableCellTextSelection: true
        };
      //this.gridOptions.rowData = createRowData();
      //this.onAddRow();
      this.gridOptions.columnDefs = this.columnDefs;
      // row height size setting
      this.getRowHeight = function(params) { return 46; };

    }

    ngOnInit() {
      // Grid 데이터 가져오기
      this.getAllValues();

      this.setDefaultSearchItems();
      //this.onSearchBoxVisible();
      document.getElementById('searchBtn').textContent = "search";
      document.getElementById('searchBtn').style.color = "#2a80b9";
      document.getElementById('searchBtn').style.display = "block";
      document.getElementById('searchSpot').innerHTML = "";
      document.getElementById('searchSpot').append(document.getElementById("_MFF_SearchBox"));
    }

    cellClick(event) {
      if (event.column.colId == 'sysFlag')
      {
        var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
        rowNode.setSelected(true);
        switch (event.value)
        {
          case "A":
            rowNode.setDataValue('sysFlag', "D");
            console.log(rowNode);
            break;
          case "D":
            rowNode.setDataValue('sysFlag', "A");
            console.log(rowNode);
            break;
          default:
            break;
        }
      }
    }

    /************************************************************
     *   버튼 및 grid event 영역
     ************************************************************/
    // 검색조건 초기화
    onClear(){
      this.search.searchContractId = '';

    }

    // grid load event
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      //params.api.sizeColumnsToFit();
      this.gridApi.hideOverlay();

      // console.log('================this.onGridReady : ');
      this.onInitPage();
    }

    onInitPage() {
      // var param: ContractData = {
      //   SiteId : AppComponent.appComp.loginUserSiteId,
      //   SiteName: "",
      //   Cls: 0,
      //   SysFlag: "",
      // };

      // this._certificationService.getValuesByParam(param).subscribe(
      //   data => this.rowData = data
      // )
    }


    // grid edit 발생 시 check box = true 를 하기위한 evnet 감지 methods
    onCellValueChanged(event) {
      if(!isNullOrUndefined(event.newValue) && event.newValue != event.oldValue) {
        var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
        rowNode.setSelected(true);

        // console.log(rowNode.data.cls);
        // 1은 Insert를 의미하기 때문에 Insert가 아닌데 Cell의 Value가 변화하는 경우는 Update로 간주
        if(rowNode.data.cls != 1) {
            rowNode.setDataValue('cls', 2); // Column Key와 Value임
        }
      }
    }

    //Grid 최하단에 신규 row 추가
    onAddRow() {
        ContractComponent.contractComp.gridApi.showLoadingOverlay();
      /*
       * service를 통한 http 호출 함수는 async로 동작함. (일반 http.get(), post() 등은 확인 못해봄)
       * 따라서 순차처리가 필요하면 아래와 같이 subscribe 영역에 Logic을 구현해야함.
       */
      let id : string;

      this._contractService.getSequenceValue().subscribe(
        //data => id
        data => {
          var today = new Date();
          var newItem = {
            productSeq: data
            //,siteName: '{필수값} '
            ,cls: 1 };
          var res = ContractComponent.contractComp.gridApi.updateRowData({ add: [newItem], addIndex: 0 });


        }
      );

      setTimeout(() => {
        ContractComponent.contractComp.gridApi.hideOverlay();
       }, 500);
      //console.log("data : " + id);
    }


    // cell renderer 에서 발생한 event 처리 결과를 받는 call back methods
    public methodFromParent(cell) {
      var cellValue = `${cell}`;
      let popUpRtnValue        = cellValue.split(';')[0];
      let fieldNm       = cellValue.split(';')[1];
      let rowIndex  = cellValue.split(';')[2];

      // console.log('methodFromParent bef : '+  popUpRtnValue+' '+fieldNm + ' ' + rowIndex);

      if(!isNullOrUndefined(popUpRtnValue) && fieldNm == "cls") {
        var rowNode = this.agGrid.api.getRowNode(rowIndex);
        rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
        rowNode.setSelected(true);

        var res = this.gridApi.updateRowData({ remove:[rowNode.data] });
        return;
      }

      if(!isNullOrUndefined(popUpRtnValue)) {
          this.setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue);
      }
    }

    // cell renderer 에서 받은 data 를 현재 grid 에 set
    setSelectedRowValue(fieldNm, rowIndex, popUpRtnValue) {
      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      rowNode.setDataValue(fieldNm, popUpRtnValue); // Column Key와 Value임
      rowNode.setSelected(true);

      if(fieldNm == "cls"){
        var selectedData = this.gridApi.getSelectedRows();
        // console.log('methodFromParent selectedData : '+  selectedData);
        var res = ContractComponent.contractComp.gridApi.updateRowData({ remove:selectedData });
      }
    }

    // Web Browser의 F5(새로고침) 기능과 동일
    refresh(): void {
      window.location.reload();
    }

    /************************************************************
     *   조회, 저장, 삭제 영역
     ************************************************************/
    // 전체 조회
    getAllValues() {
      this._contractService.getAllValues().subscribe(
        data => this.rowData = data.body
      )
    }

      // pop up 조회 버튼 클릭
    public onSearch(search) : void{
        ContractComponent.contractComp.gridApi.showLoadingOverlay();
      if(isNullOrUndefined(search)) {
        var param: ContractData = {
          ContractId: "",
          CrtfdOnwer: "",
          CrtfdOnwerDiv: "",
          CrtfdOnwerInfo: "",
          CrtfdStDt: "",
          CrtfdExpDt: "",
          CrtfdMinVersion: "",
          CrtfdVersion: "",
          CrtfdPolicy: "",
          ProductSeq: "",
          ProductCls: "",
          ProductKey:"",
          CrtfdAllowCnt: "",
          CrtfdAuthInfo: "",
          CrtfdAuthFeature: "",
          ContractDesc: "",
          CrtfdPeriod: "",
          Cls: 0,
          SysFlag: ""
        };
      } else {
        var param: ContractData = {
          ContractId: this.search.searchContractId,
          CrtfdOnwer: "",
          CrtfdOnwerDiv: "",
          CrtfdOnwerInfo: "",
          CrtfdStDt: "",
          CrtfdExpDt: "",
          CrtfdMinVersion: "",
          CrtfdVersion: "",
          CrtfdPolicy: "",
          ProductSeq: "",
          ProductCls: "",
          ProductKey:"",
          CrtfdAllowCnt:"",
          CrtfdAuthInfo: "",
          CrtfdAuthFeature: "",
          ContractDesc: "",
          CrtfdPeriod: "",
          Cls: 0,
          SysFlag: ""
        };
      }

      this._contractService.getValuesByContractId(param).subscribe(
        data => this.rowData = data
      )

      // console.log('getSelectedRow : '+ this.rowData.contractId + ' '+ this.rowData.crtfdOnwer + ' ' +this.rowData.crtfdOnwerDiv + ' '+ this.rowData.crtfdOnwerInfo);

     setTimeout(() => {
        ContractComponent.contractComp.gridApi.hideOverlay();
       }, 1000);

    }

    getSelectedRows(_cls): ContractData[] {
      var _ContractDataList: ContractData[] = new Array<ContractData>();
      var selectedData = ContractComponent.contractComp.gridApi.getSelectedRows();

      // console.log('selectedData : '+ selectedData);

      selectedData.forEach(function(row, index) {

        if(_cls != 3) {
          var ContractData: ContractData = {
            ContractId: row.contractId,
            CrtfdOnwer: row.crtfdOnwer,
            CrtfdOnwerDiv: row.crtfdOnwerDiv,
            CrtfdOnwerInfo: row.crtfdOnwerInfo,
            CrtfdStDt: row.crtfdStDt,
            CrtfdExpDt: row.crtfdExpDt,
            CrtfdMinVersion: row.crtfdMinVersion,
            CrtfdVersion: row.crtfdVersion,
            CrtfdPolicy: row.crtfdPolicy,
            ProductSeq: row.productSeq,
            ProductCls: row.productCls,
            ProductKey: row.productKey,
            CrtfdAllowCnt: row.crtfdAllowCnt,
            CrtfdAuthInfo: row.crtfdAuthInfo,
            CrtfdAuthFeature: row.crtfdAuthFeature,
            ContractDesc: row.contractDesc,
            CrtfdPeriod: row.crtfdPeriod,
            Cls: row.cls,
            SysFlag: row.sysFlag
          };

          _ContractDataList.push(ContractData);
        } else {
          var ContractData: ContractData = {
            ContractId: row.contractId,
            CrtfdOnwer: row.crtfdOnwer,
            CrtfdOnwerDiv: row.crtfdOnwerDiv,
            CrtfdOnwerInfo: row.crtfdOnwerInfo,
            CrtfdStDt: row.crtfdStDt,
            CrtfdExpDt: row.crtfdExpDt,
            CrtfdMinVersion: row.crtfdMinVersion,
            CrtfdVersion: row.crtfdVersion,
            CrtfdPolicy: row.crtfdPolicy,
            ProductSeq: row.productSeq,
            ProductCls: row.productCls,
            ProductKey: row.productKey,
            CrtfdAllowCnt: row.crtfdAllowCnt,
            CrtfdAuthInfo: row.crtfdAuthInfo,
            CrtfdAuthFeature: row.crtfdAuthFeature,
            ContractDesc: row.contractDesc,
            CrtfdPeriod: row.crtfdPeriod,
            Cls: _cls,
            SysFlag: row.sysFlag
          };

          _ContractDataList.push(ContractData);
        }

      //   _siteList.forEach(function(data) {
      //   console.log('getSelectedRow : '+data.siteId + ' '+ data.ProjectName + ' ' +data.StrtDate + ' '+ data.Cls + ' '+data.Flag);
      // });

      });

      // console.log(_ContractDataList);
      return _ContractDataList;
    }

    saveValues() {
      var paramList = ContractComponent.contractComp.getSelectedRows(1);
      ContractComponent.contractComp.gridApi.showLoadingOverlay();

      paramList.some(function (data) {
        // 인증시작일 체크
        if(data.CrtfdStDt == '' || isNullOrUndefined(data.CrtfdStDt)) {
          data.CrtfdStDt = ContractComponent.contractComp.datepipe.transform(new Date(), 'yyyy-MM-dd');
          data.CrtfdStDt = data.CrtfdStDt + " 00:00:00";
        }

        // 인증만료일 체크
        if(data.CrtfdExpDt == '' || isNullOrUndefined(data.CrtfdExpDt)) {
          data.CrtfdExpDt = ContractComponent.contractComp.datepipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd');
          data.CrtfdExpDt = data.CrtfdExpDt + " 00:00:00";
        }
      });

      // console.log('saveValues : '+ paramList.contractId);

      this._contractService.saveValuesByParams(paramList).subscribe(
        data => {
          if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
            //SAVE Query Fail
            // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
            alert("저장 처리 중 오류가 발생했습니다.");
          } else {
            //SAVE Query Success
            data;

            var answer = window.confirm("수정된 내용을 바탕으로 이 제품키에 대한 인증내역을 갱신하도록 변경하시겠습니까? ('확인'을 클릭할 경우, CERT_FLAG가 Y로 변경됨)");
            if (answer) {
              this._contractService.refreshCertFlagInCrtfdHst(paramList).subscribe(
                data => {
                  if(JSON.stringify(data).split("   at")[0].indexOf("ERROR") > -1) {
                    //SAVE Query Fail
                    // alert(JSON.stringify(data).split("   at")[0].substring(10, JSON.stringify(data).split("   at")[0].length));
                    alert("갱신 처리 중 오류가 발생했습니다.");
                  } else {
                    //SAVE Query Success
                    data;
                  }
                }
              );
            }
            //this.refresh();
          }
        }
      );
      setTimeout(() => {
        ContractComponent.contractComp.gridApi.hideOverlay();
        // console.log("save->siteName: " + this.search.searchContractId,);
        ContractComponent.contractComp.onSearch(this.search)
      }, 1000);
    }

    deleteValues() {
      var paramList = ContractComponent.contractComp.getSelectedRows(3);

      this._contractService.deleteValuesByParams(paramList).subscribe(
        data => {
          data; // 미사용
          // row data refresh
          //this.refresh();
        }
      );
      setTimeout(() => {
        ContractComponent.contractComp.gridApi.hideOverlay();
        // console.log("save-> ContractId: " + this.search.searchContractId);
        ContractComponent.contractComp.onSearch(this.search)
      }, 1000);
    }

    setDefaultSearchItems() {
      this.search.searchContractId = "";
      // this.getAllValues();
    }

    onSearchBoxVisible() {
      if (document.getElementById('_MFF_SearchBox').hidden == true) {
        document.getElementById('searchBtn').textContent = "clear";
        document.getElementById('searchBtn').style.color = "#717171";
        document.getElementById('searchSpot').hidden = false;
        document.getElementById('_MFF_SearchBox').hidden = false;
      } else {
        document.getElementById('searchBtn').textContent = "search";
        document.getElementById('searchBtn').style.color = "#2a80b9";
        document.getElementById('searchSpot').hidden = true;
        document.getElementById('_MFF_SearchBox').hidden = true;
      }
    }

    setSelectedRowForValue(date, rowIndex, fieldNm) {

      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      rowNode.setDataValue(fieldNm, date); // Column Key와 Value임
      rowNode.setSelected(true);
    }

    onCellClicked(event) {

      // 계약번호 클릭 시 pop up
      if(event.column.colId == "contractId")
      {
        // console.log(event);
        // console.log("event-> productSeq: " + event.data.siteId);

        if(isNullOrUndefined(event.data.productCls) || event.data.productCls == '') {
          alert("제품구분코드는 필수 입력 입니다.");
          return;
        }

        if(isNullOrUndefined(event.data.contractId) || event.data.contractId == '') {
            //선택한 ROW의 META_ID 데이터가 NULL일 경우
            console.log("event.data.productSeq > " + event.data.productSeq);
            const dialogRef = ContractComponent.matDial.open(PopUpDialogContractComponent, {
              width: '400px', height : '380px',
              data: event.data.productSeq
            });

            dialogRef.afterClosed().subscribe(result => {
              if(!isNullOrUndefined(result)) {
                var rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
                rowNode.setDataValue('contractId', result[0]);
                //rowNode.setDataValue('productCls', result[1]);
                rowNode.setDataValue('productKey', result[2]);
                rowNode.setDataValue('productSeq', result[3]);
                rowNode.setDataValue('crtfdAuthFeature', result[4]);
                // console.log("setDataValue-> " + result[0] + " : " + result[1] + " : " + result[2] + " : " + result[3]);
              }
            });
          }
      }
    }

    setDatepickerRowForValue(date, rowIndex, fieldNm) {
      var rowNode = this.agGrid.api.getRowNode(rowIndex);
      var dateFormat = this.datepipe.transform(date, 'yyyy-MM-dd');
      dateFormat = dateFormat + " 00:00:00";
      rowNode.setDataValue(fieldNm, dateFormat); // Column Key와 Value임
      rowNode.setSelected(true);
    }
  } // end class

  function createRowData() {
    var rowData = [];
    for (var i = 0; i < 1; i++) {
      rowData.push({
        row: "Row " + i,
        value: i,
        currency: i + Number(Math.random().toFixed(2))
      });
    }
    return rowData;
  }

  function numberValueParser(params) {
    return Number(params.newValue);
  }

  function formatNumber(number) {

    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
